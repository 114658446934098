import TableChart from "@mui/icons-material/TableChart";
import PendingIcon from "@mui/icons-material/Pending";

const table_list = [
    // {
    //     title: "Import",
    //     icon: ArrowUpward,
    //     route: "/import",
    // },
    // {
    //     title: "New Entry",
    //     icon: AddBox,
    //     route: "/entry",
    // },
    {
        title: "Order Table",
        icon: TableChart,
        route: "/table",
    },
    {
        title: "Waiting for approval",
        icon: PendingIcon,
        route: "/orders",
    },
];

const secondaryLists = [
    {
        name: "SIM",
        items: table_list,
        //  permission_name: "table"
    },
    
];

export default secondaryLists;
