import React, { useState, useEffect, useCallback } from "react";
import { Container, CssBaseline, Box, Avatar, Alert } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import { useLocation, useNavigate } from "react-router-dom";
import getSSORedirect from "../../../api/sso_api";
import { user_state } from "../../root";
import { useSetRecoilState } from "recoil";
import jwt_decode from "jwt-decode";

const LoginPage = () => {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setUserState = useSetRecoilState(user_state);

  const handleSSOLogin = async () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.setItem("path", location.pathname);
      await getSSORedirect();
    } catch (e) {
      setLoading(false);
      setError("Failed to login! Please reach out to Data analytics team!");
    }
  };

  const setNewUserCallback = useCallback(() => {
    const setNewUser = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get("token");
      const email = params.get("email");
      if (token && email) {
        try {
          setUserState({ token, user: email });
          localStorage.setItem("user", email);
          localStorage.setItem("token", token);
        } catch (e) {
          setError("Existing token expired, please sign-in again");
          console.trace(e);
        }
        setLoading(false);
        if (token) {
          const path = localStorage.getItem("path");
          if (path) {
            navigate(path);
            localStorage.removeItem("path");
          } else {
            navigate("/");
          }
        }
      }
    };
    setNewUser();
  }, [setUserState, navigate, location.search]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const email = localStorage.getItem("user");
    if (!token || !email) {
      setNewUserCallback();
    } else if (Date.now() >= jwt_decode(token).exp * 1000) {
      setNewUserCallback();
    } else {
      setUserState({ token, user: email });
    }
  }, [setNewUserCallback, setUserState]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Box component="form" noValidate sx={{ mt: 1 }}>
          <LoadingButton
            loading={loading}
            onClick={() => handleSSOLogin()}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign in with SSO
          </LoadingButton>
        </Box>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
      {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
    </Container>
  );
};

export default LoginPage;
