import React, { useState, useEffect } from "react"
import { columns } from "../order_table/constants"
import { CssBaseline } from "@mui/material"
import FinalTable from "../../organisms/table"
import { callGraphQL } from "../../../api/graphql_api"
import { useParams } from "react-router-dom"

export const OrderViewPage = () => {

  // eslint-disable-next-line
  const [viewData, setViewData] = useState([]);
  const { orderId } = useParams();

    const getViewData = React.useCallback(async () => {
      try {
        // call sim orders related to orderId
        
        const resp = await callGraphQL('get_orders');
        setViewData(resp.data.data.get_all_orders.orders);

      } catch (err) {
        console.log(err)
      }
    }, [])

    useEffect(() => {
      getViewData();
    },[getViewData]);

    const data = React.useMemo(
        () => viewData, [viewData]
    )
    const columnsMemo = React.useMemo(
        () => columns, []
    )

      return (
        <>
         <CssBaseline />
         <FinalTable columns={columnsMemo} data={data} orderView={true} />
        </>
      )
}

export default OrderViewPage