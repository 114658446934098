import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import React from "react";
import { RecoilRoot } from "recoil"

function App() {
    return (
        <RecoilRoot>
        <div className="App">
            <Router>
            <Routes />
            </Router>
        </div>
        </RecoilRoot>
       
    );
}

export default App;
