const FIELD_SIZE = {
    full: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    xl: { xs: 12, sm: 12, md: 6, lg: 6, xl: 6 },
    large: { xs: 12, sm: 12, md: 6, lg: 6, xl: 4 },
    medium: { xs: 12, sm: 12, md: 4, lg: 3, xl: 2 },
    small: { xs: 12, sm: 6, md: 3, lg: 3, xl: 3 },
    tiny: { xs: 12, sm: 2, md: 2, lg: 2, xl: 2 },
    half: { xs: 12, sm: 6, md: 6, lg: 6, xl: 6 },
};

export default FIELD_SIZE;
