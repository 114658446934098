import React, { useState, useEffect } from "react";
import { columns } from "./constants";
import { CssBaseline } from "@mui/material";
import FormDialog from "../../organisms/edit_popup";
import FinalTable from "../../organisms/table";
import { callGraphQL } from "../../../api/graphql_api";
import { v4 as uuidv4 } from "uuid";

export const TablePage = () => {
  const [viewData, setViewData] = useState([]);
  const [batch, setBatch] = useState(false);
  const [add, setAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editRowData, setEditRowData] = useState({});

  const getViewData = React.useCallback(async () => {
    try {
      let token = "";
      let hasMore = true;
      let resp;
      const limit = 2;
      let data = [];
      while (hasMore) {
        resp = await callGraphQL("get_orders", {
          pagination: { limit: 300, token },
        });
        data.push(...resp.data.data.get_all_orders.orders)
        token = resp.data.data.get_all_orders.pagination_token;
        hasMore = token !== null;
        // await new Promise((r) => setTimeout(r, 5000));
      }
      setViewData(data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getViewData();
  }, [getViewData]);

  const data = React.useMemo(() => viewData, [viewData]);
  const columnsMemo = React.useMemo(() => columns, []);

  const setEdit = (row, batch = false, add = false) => {
    setBatch(batch);
    setAdd(add);
    setOpenEdit(true);
    setEditRowData(row);
  };

  const handleSave = async (formData) => {
    let orders;
    if (batch) {
      const ordersToEdit = editRowData.map((row) => row.original);
      orders = ordersToEdit.map((order) => {
        return { ...order, ...formData };
      });
    } else if (add) {
      orders = {
        ...formData,
        entity: "order",
        creation_time: `${new Date().toISOString()}`,
        id: uuidv4(),
      };
    } else {
      orders = formData;
    }
    console.log(orders);
    await callGraphQL("update_orders", { orders });
    getViewData();
    handleClose();
  };

  const handleClose = () => {
    setOpenEdit(false);
    setBatch(false);
    setAdd(false);
    setEditRowData({});
  };

  return (
    <>
      <CssBaseline />
      <FinalTable columns={columnsMemo} data={data} setEdit={setEdit} />
      <FormDialog
        isOpen={openEdit}
        rowData={editRowData}
        handleSave={handleSave}
        handleClose={handleClose}
        batch={batch}
        add={add}
      />
    </>
  );
};

export default TablePage;
