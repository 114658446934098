import React, { useState, useEffect } from "react";
import { callGraphQL } from "../../../api/graphql_api";
import GlobalFilter from "../../molecules/global_filter";
import DefaultColumnFilter from "../../molecules/default_column_filter";
import GridField from "../../molecules/grid_field";
import FIELD_SIZE from "../../../constants";
import TablePagination from "../../molecules/pagination";
import TableCardRow from "../../molecules/table_card_render";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import {
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid,
  Collapse,
  Switch,
  Box,
  FormControlLabel,
  Button,
  Chip,
  IconButton,
} from "@mui/material";

import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
// Our table component
const FinalTable = ({ columns, data, setEdit, orderView = false }) => {
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState([])
  const [queryStrings, setQueryStrings] = useState([]);
  const [filterActive, setFilterStatus] = useState(false);
  // setOrderData(data);
  // const approveOrder = async (approve_id, approve_status) => {
  //   console.log("Approving!");
  //   const resp = await callGraphQL("approve_orders", {
  //     approve_id,
  //     approve_status,
  //   });
  // };

  const getFiltredData = useEffect(async () => {
    if(queryStrings.length > 0){
      const resp = await callGraphQL("filter_orders", {
        query_parameters: JSON.stringify(queryStrings),
      });
      setOrderData(resp.data.data.filter_orders.orders);
      setFilterStatus(true);
    }else{
      setFilterStatus(false);
    }
  }, [queryStrings]);

  const toggleHeaders = () => {
    setOpen((prev) => !prev);
  };

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  // console.log(`ORDER DATA: ${orderData}`)
  const resp = useTable(
    {
      columns,
      data: filterActive ? orderData : data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <Checkbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
    setFilter,
    //pagination stuff
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = resp;
  // console.log(rows)
  return (
    <>
      <Table {...getTableProps()} sx={{ tableLayout: "fixed" }}>
        <TableHead>
          <TableRow>
            <TableCell
              colSpan={visibleColumns.length}
              sx={{
                textAlign: "left",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <GlobalFilter
                rows={rows}
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                useAsyncDebounce={useAsyncDebounce}
                data={data}
                setQueryStrings={setQueryStrings}
              />
              <Box>
                <FormControlLabel
                  control={<Switch checked={open} onChange={toggleHeaders} />}
                  label="Show filters and sorting"
                />
                <Button
                  variant="contained"
                  disabled={state.filters == 0 && state.sortBy == 0}
                  onClick={() => {
                    setAllFilters([]);
                    setSortBy([]);
                  }}
                >
                  Clear all filters
                </Button>
              </Box>
              <TablePagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
              {!orderView && (
                <>
                  <Button
                    onClick={() => setEdit(rows, false, true)}
                    sx={{ margin: 1 }}
                    variant="contained"
                  >
                    Add Order
                  </Button>
                </>
              )}
            </TableCell>
          </TableRow>
          {state.filters.length + state.sortBy.length > 0 && (
            <TableRow>
              <TableCell
                sx={{ justifyContent: "space-between", borderBottom: "none" }}
              >
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, i) => {
                    return column.isSorted ? (
                      <Chip
                        key={`${i}_filterValue`}
                        label={`Sort by: ${column.Header} (${
                          column.isSortedDesc ? "Desc" : "Asc"
                        })`}
                        onDelete={() => {
                          setSortBy([]);
                        }}
                      />
                    ) : null;
                  })
                )}
                {headerGroups.map((headerGroup) =>
                  headerGroup.headers.map((column, i) => {
                    return column.filterValue ? (
                      <Chip
                        key={`${i}_filterValue`}
                        label={`${column.Header} = ${column.filterValue}`}
                        onDelete={() => {
                          setFilter(column.id, "");
                        }}
                      />
                    ) : null;
                  })
                )}
              </TableCell>
            </TableRow>
          )}
          {headerGroups.map((headerGroup) => {
            if (!open) {
              return;
            } else {
              return (
                <TableRow
                  {...headerGroup.getHeaderGroupProps()}
                  sx={{ overflow: "hidden" }}
                >
                  <TableCell
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      borderBottom: open ? "" : "none",
                    }}
                  >
                    <Collapse in={open}>
                      <Grid container spacing={1} alignItems="center">
                        {headerGroup.headers.map((column, i) => {
                          if (i === 0 || i === 1) return null;
                          return (
                            // <div {...column.getHeaderProps()} style={{margin: 5}} key={i}>
                            //   {column.render('Header')}
                            //   <div>{column.canFilter ? column.render('Filter') : null}</div>
                            // </div>

                            <GridField
                              key={`${i}_gridField`}
                              size={FIELD_SIZE.tiny}
                            >
                              <div style={{ textAlign: "center" }}>
                                <div
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                  style={{ fontSize: 12, color: "black" }}
                                >
                                  {column.render("Header")}
                                  <span>
                                    {column.isSorted ? (
                                      column.isSortedDesc ? (
                                        <IconButton color="primary">
                                          <ArrowDropDownCircleOutlinedIcon />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          color="primary"
                                          sx={{
                                            transform:
                                              "rotate(180deg) scaleX(-1)",
                                          }}
                                        >
                                          <ArrowDropDownCircleOutlinedIcon />
                                        </IconButton>
                                      )
                                    ) : (
                                      <IconButton
                                        sx={{
                                          transform:
                                            "rotate(180deg) scaleX(-1)",
                                        }}
                                      >
                                        <ArrowDropDownCircleOutlinedIcon />
                                      </IconButton>
                                    )}
                                  </span>
                                </div>
                                <div>
                                  {column.canFilter
                                    ? column.render("Filter")
                                    : null}
                                </div>
                              </div>
                            </GridField>
                          );
                        })}
                      </Grid>
                    </Collapse>
                  </TableCell>
                </TableRow>
              );
            }
          })}
          <TableRow>
            <TableCell
              colSpan={visibleColumns.length}
              sx={{
                borderBottom: "none",
                textAlign: "left",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {headerGroups[0].headers[0].render("Header")}
              {selectedFlatRows.length} rows selected
              {!orderView && (
                <>
                  <Button
                    disabled={selectedFlatRows.length === 0}
                    onClick={() =>
                      selectedFlatRows.length > 0
                        ? setEdit(selectedFlatRows, true)
                        : null
                    }
                    sx={{ margin: 1 }}
                    variant="contained"
                  >
                    Batch Edit
                  </Button>
                </>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <TableCardRow
                row={row}
                i={i}
                key={`${i}_pageRow`}
                setEdit={setEdit}
                orderView={orderView}
              />
            );
          })}
          <TableRow sx={{ textAlign: "center !important" }}>
            <TableCell
              colSpan={visibleColumns.length}
              sx={{
                borderTop: "1px solid",
                borderColor: "rgba(224, 224, 224, 1)",
                textAlign: "left",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TablePagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default FinalTable;
