import React from "react";
import { Table, TableBody, TableHead } from "@mui/material";
import { columns } from "../../pages/order_table/constants";
import TableCardRow from "../../molecules/table_card_render";

import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
} from "react-table";

const InnerTable = ({ data }) => {
  const resp = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const { getTableProps, prepareRow, rows } = resp;

  return (
    <Table {...getTableProps()}>
      <TableHead></TableHead>
      <TableBody>
        {rows.map((row, i) => {
          prepareRow(row);
          return <TableCardRow row={row} i={i} key={`${i}_pageRow`} orderView={true}/>;
        })}
      </TableBody>
    </Table>
  );
};

export default InnerTable;
