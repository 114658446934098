import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  TableCell,
  TableRow,
  Grid,
  Card,
  CardContent,
  Collapse,
} from "@mui/material";
import InnerTable from "./innerTable";

const ApproveCardRow = ({ row, i }) => {
  const allRow = [...row.cells];
  const expander = allRow.shift();
  return (
    <TableRow key={`${row.id}_row`}>
      <TableCell>
        <Card variant="outlined">
          <CardContent sx={{ display: "flex" }}>
            <Collapse
              sx={{ width: "100%" }}
              in={row.isExpanded}
              collapsedSize={60}
            >
              <Grid container spacing={2} columns={6}>
                {allRow.map((cell, i) => (
                  <Grid
                    key={`${i}_cell`}
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "5px",
                        fontSize: 10,
                        color: "gray",
                      }}
                    >
                      {cell.render("Header")}
                    </Box>
                    <Box>{cell.render("Cell")}</Box>
                  </Grid>
                ))}
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  sx={{
                    marginTop: "10px",
                    textAlign: "center",
                  }}
                >
                  <InnerTable data={row.original.orders}></InnerTable>
                </Grid>
              </Grid>
            </Collapse>
            <div sx={{ borderBottom: "none", verticalAlign: "top" }}>
              {expander.render("Cell")}
            </div>
          </CardContent>
        </Card>
      </TableCell>
    </TableRow>
  );
};
ApproveCardRow.propTypes = {
  row: PropTypes.object,
  i: PropTypes.number,
};
export default ApproveCardRow;
