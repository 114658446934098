import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import _cloneDeep from "lodash.clonedeep";
import { Grid, TextField } from '@mui/material';
import GridField from '../../molecules/grid_field';
import FIELD_SIZE from '../../../constants';

const FormDialog = ({isOpen, rowData = {}, handleSave, handleClose, batch, add}) => {
  const [editData, setEditData] = React.useState({});

  const handleChange = (value, id) => {
    setEditData((old) => {
      return {...old, [id]: value };
    })
  }
  React.useEffect(() => {
    const data = (batch || add) ? {} : {...rowData.original}
    setEditData(_cloneDeep(data))
  },[rowData, batch, add])
  const cells = (batch || add) ? rowData[0].cells : rowData.cells;
  const columnData = cells ? _cloneDeep(cells) : [];
  columnData.shift();
  columnData.shift();
  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"xl"} open={isOpen} onClose={handleClose}>
        <DialogTitle>{batch ? `Batch Editing ${rowData.length} SIM orders` : "Edit SIM order"} </DialogTitle>
        <DialogContent>
                    <Grid  sx={{marginTop: "0px"}} container spacing={2} columns={15} alignItems="center">
                        {
                        columnData.map((cell, index) => (
                            <GridField key={`${index}_gridField`} size={FIELD_SIZE.small}>
                              <TextField 
                                sx={{width: "100%"}}
                                label={cell?.column?.Header}
                                value={editData[cell?.column?.id] || ""}
                                onChange={(e) => handleChange(e.target.value, cell.column.id)}
                              />
                            </GridField>
                          )
                        )
                        }
                      </Grid> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleSave(editData)}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;