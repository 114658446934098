import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const DefaultColumnFilter = ({
  rows,
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = rows.length;
  return (
    <TextField
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.object,
};

export default DefaultColumnFilter;
