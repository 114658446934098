import axios from "axios";
import { 
    get_orders_query, 
    get_orders_by_approve_id,
    update_orders_query,
    approve_orders_query,
    get_pending_orders,
    get_approved_orders,
    get_denied_orders,
    filter_orders_query
} from "./query_constants";

export const callGraphQL = async (type, variables) => {
    let query;
    switch (type) {
        case "get_orders":
            query = get_orders_query;
            break;
        case "get_orders_by_apporve_id":
            query = get_orders_by_approve_id;
            break;
        case "get_pending_orders":
            query = get_pending_orders;
            break;
        case "get_approved_orders":
            query = get_approved_orders;
            break;
        case "get_denied_orders":
            query = get_denied_orders;
            break;
        case "approve_orders":
            query = approve_orders_query;
            variables = { ...variables, email: localStorage.getItem("user") };
            console.log(variables)
            break;
        case "update_orders":
            query = update_orders_query;
            variables = { ...variables, email: localStorage.getItem("user") };
            break;
        case "filter_orders":
            query = filter_orders_query;
            variables = { ...variables};
        break;
        default:
            break;
    }
    const options = {
        headers: {
            "Content-Type": "application/json",
            // "x-api-key": process.env.REACT_APP_GRAPHQL_KEY,
            Authorization: localStorage.getItem("token"),
        },
    };
    const url = process.env.REACT_APP_GRAPHQL_URL;
    try {
        const resp = await axios.post(url, { query, variables }, options);
        // console.log(resp)
        return resp;
    } catch (err) {
        console.log(err);
    }
};

