///Get all orders///

export const get_orders_query = `query MyQuery($pagination: dynamo_pagination) {
  get_all_orders(pagination: $pagination, latests_only: true) {
    orders {
     creation_time
      version
      vendor_profile
      vendor
      status
      specification
      sku_size
      sim_type
      sim_chip
      quantity
      product_name
      profile
      process_number
      process
      price
      perso_code
      pallete_quantity
      order_comment
      msisdn_start
      msisdn_end
      imsi_start
      imsi_end
      iccid_start
      id
      iccid_end
      form_factor
      graphical_reference
      entity
      edrx_ef_ad
      ean_grade
      ean_code
      date_po
      date_of
      date_if
      date_csv
      date_bss
      card_size
      bss_order_id
      box_quantity
      address_pool
      approve_id
      batch_id
      applet_multi_imsi
    }
    pagination_token
  }
}`;

export const get_orders_by_approve_id = `query MyQuery($approve_id: ID!) {
  get_orders_by_approve_id(approve_id: $approve_id) {
    pagination_token
    orders {
      id
      version
      vendor_profile
      vendor
      status
      quantity
      sim_chip
      sim_type
      sku_size
      specification
      profile
      product_name
      process_number
      process
      price
      perso_code
      pallete_quantity
      order_comment
      msisdn_start
      msisdn_end
      imsi_start
      imsi_end
      iccid_start
      iccid_end
      graphical_reference
      form_factor
      entity
      edrx_ef_ad
      ean_grade
      ean_code
      date_po
      date_of
      date_if
      date_csv
      date_bss
      creation_time
      card_size
      bss_order_id
      batch_id
      box_quantity
      approve_id
      applet_multi_imsi
      address_pool
      updated_attributes
    }
  }
}
`;

///Get pending orders///
export const get_pending_orders = `query MyQuery($pagination: dynamo_pagination) {
  get_approve_requests(pagination: $pagination, request_status: pending) {
    pagination_token
    approve_requests {
      status
      entity_type
      requered_approvers
      eligible_approvers
      created_by
      approved_by
      approve_id
    }
  }
}`;

///Get accepted orders///
export const get_approved_orders = `query MyQuery($pagination: dynamo_pagination) {
  get_approve_requests(pagination: $pagination, request_status: approved) {
    pagination_token
    approve_requests {
      status
      entity_type
      requered_approvers
      eligible_approvers
      created_by
      approved_by
      approve_id
    }
  }
}`;

///Get denied orders///
export const get_denied_orders = `query MyQuery($pagination: dynamo_pagination) {
  get_approve_requests(pagination: $pagination, request_status: declined) {
    pagination_token
    approve_requests {
      status
      entity_type
      requered_approvers
      eligible_approvers
      created_by
      approved_by
      approve_id
    }
  }
}`;
export const filter_orders_query = `query MyQuery($query_parameters: String!) {
  filter_orders(query_parameters: $query_parameters) {
    pagination_token
    orders {
      id
      version
      vendor_profile
      vendor
      status
      quantity
      sim_chip
      sim_type
      sku_size
      specification
      profile
      product_name
      process_number
      process
      price
      perso_code
      pallete_quantity
      order_comment
      msisdn_start
      msisdn_end
      imsi_start
      imsi_end
      iccid_start
      iccid_end
      graphical_reference
      form_factor
      entity
      edrx_ef_ad
      ean_grade
      ean_code
      date_po
      date_of
      date_if
      date_csv
      date_bss
      creation_time
      card_size
      bss_order_id
      batch_id
      box_quantity
      approve_id
      applet_multi_imsi
      address_pool
      updated_attributes
    }
  }
}`;

export const approve_orders_query = `mutation MyQuery ($approve_id: ID!, $email: String!, $approve_status: Boolean){
  approve_orders(approve_id: $approve_id, email: $email, approve_status: $approve_status)
}`;

export const update_orders_query = `mutation MyQuery ($orders: [order_input!]!, $email: String!){
  update_orders(orders: $orders, email: $email)
}`;
