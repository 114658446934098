import React from "react";
import PropTypes from "prop-types";
import {
  TableCell,
  TableRow,
  Grid,
  Card,
  CardContent,
  Collapse,
  Box,
} from "@mui/material";
import GridField from "../../molecules/grid_field";
import FIELD_SIZE from "../../../constants";
import EditIcon from "@mui/icons-material/Edit";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

const TableCardRow = ({ row, i, setEdit, orderView }) => {
  const allRow = [...row.cells];
  let checkBox;
  if (!orderView) {
    checkBox = allRow.shift();
  }
  const expander = allRow.shift();
  const updatedAttributesString = row.original.updated_attributes;
  const updatedAttributes = updatedAttributesString
    ? JSON.parse(row.original.updated_attributes)
    : {};
  // const EditableCell = ({
  //     value: initialValue,
  //     row: { index },
  //     column: { id },
  //     updateMyData, // This is a custom function that we supplied to our table instance
  //   }) => {
  //     // We need to keep and update the state of the cell normally
  //     const [value, setValue] = React.useState(initialValue)

  //     const onChange = e => {
  //       setValue(e.target.value)
  //     }

  //     // We'll only update the external data when the input is blurred
  //     const onBlur = () => {
  //       updateMyData(index, id, value)
  //     }

  //     // If the initialValue is changed external, sync it up with our state
  //     React.useEffect(() => {
  //       setValue(initialValue)
  //     }, [initialValue])

  //     return <input value={value} onChange={onChange} onBlur={onBlur} />
  //   }
  return (
    <TableRow key={`${i}_tableRow`}>
      <TableCell sx={{ borderBottom: "none", width: "100%" }}>
        <Card variant="outlined">
          <CardContent sx={{ display: "flex" }}>
            {(() => {
              if (!orderView) {
                return (
                  <Box
                    sx={{
                      borderBottom: "none",
                      verticalAlign: "top",
                      pt: "20px !important",
                    }}
                  >
                    {checkBox.render("Cell")}
                  </Box>
                );
              }
            })()}

            <Collapse
              sx={{ width: "100%" }}
              in={row.isExpanded}
              collapsedSize={50}
            >
              <Grid
                sx={{ marginTop: row.isExpanded ? 0 : "0px" }}
                container
                spacing={2}
                columns={24}
                alignItems="center"
                {...row.getRowProps()}
              >
                {allRow.map((cell, index) => {
                  return (
                    <GridField
                      key={`${index}_gridField`}
                      size={FIELD_SIZE.small}
                    >
                      <div style={{ textAlign: "center" }}>
                        <div style={{ fontSize: 10, color: "gray" }}>
                          {cell.column.Header}
                        </div>
                        <div>
                          {updatedAttributes[cell.column.id] ? (
                            <>
                              {
                                <Tooltip
                                  title={cell.render("Cell")}
                                  placement="top"
                                >
                                  <Button>
                                    {updatedAttributes[cell.column.id]}
                                  </Button>
                                </Tooltip>
                              }
                            </>
                          ) : (
                            cell.render("Cell")
                          )}
                        </div>
                      </div>
                    </GridField>
                  );
                })}
              </Grid>
            </Collapse>
            <div sx={{ borderBottom: "none", verticalAlign: "top" }}>
              {expander.render("Cell")}
              {!orderView && (
                <div>
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setEdit(row)}
                  />
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </TableCell>
    </TableRow>
  );
};
TableCardRow.propTypes = {
  row: PropTypes.object,
  i: PropTypes.number,
};
export default TableCardRow;
