import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1e4d7d",
        },
        secondary: {
            main: "#29abe2",
        },
    },
});

export const drawerWidth = 240;

export default theme;
