import React from "react";
import PropTypes from "prop-types";
import { Stack, Button, Select, MenuItem, TextField } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


const TablePagination = ({gotoPage, canPreviousPage, previousPage, nextPage, canNextPage, pageCount, pageIndex, pageOptions,pageSize, setPageSize}) => {
    return (
        <Stack className="pagination" direction="row" spacing={1}>
            <Stack direction="row">
                <Button size="small" variant="text" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <KeyboardDoubleArrowLeftIcon />
                </Button>
                <Button size="small" variant="text" onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <ChevronLeftIcon />
                </Button>
            </Stack>
           <div style={{fontSize: "16px",lineHeight: "40px"}}>
                <span>
                    {pageIndex + 1} of {pageOptions.length}
                </span>
           </div>
           <Stack direction="row" spacing={1}>
                <Button size="small" variant="text" onClick={() => nextPage()} disabled={!canNextPage}>
                    <ChevronRightIcon />
                </Button>
                <Button size="small" variant="text" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <KeyboardDoubleArrowRightIcon />
                </Button>
           </Stack>
           <div>
                <span style={{fontSize: "16px",lineHeight: "40px", marginRight: "5px"}}>
                    Go to page: 
                </span>
                <TextField
                    sx={{width: "70px"}}
                    type="number"
                    size="small"
                    defaultValue={pageIndex + 1}
                    onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                    }}
                />
            </div>
            <Select
                size="small"
                id="page-size-select"
                value={pageSize}
                onChange={e => {
                    setPageSize(Number(e.target.value))
                }}
            >
            {[10, 20, 30, 40, 50].map(pageSize => (
                <MenuItem key={pageSize} value={pageSize}>
                Show {pageSize}
                </MenuItem>
            ))}
            </Select>
        </Stack>
    )
}

TablePagination.propTypes = {
    gotoPage: PropTypes.func,
    canPreviousPage: PropTypes.bool,
    previousPage: PropTypes.func,
    nextPage: PropTypes.func,
    canNextPage: PropTypes.bool,
    pageCount: PropTypes.number,
    pageIndex: PropTypes.number,
    pageOptions: PropTypes.array,
    pageSize: PropTypes.number,
    setPageSize: PropTypes.func,
  };



export default TablePagination;