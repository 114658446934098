import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Button } from "@mui/material";

const valid_keys = {
  lsi2: "state",
  vendor: "vendor",
  batch_id: "batch id",
  process: "process",
  process_number: "process number",
  iccid_start: "iccid start",
  iccid_end: "iccid end",
  jira: "jira",
  quantity: "quantity",
  form_factor: "form factor",
  date_po: "date (po)",
  bss_order_id: "bss order id",
  ean_code: "ean code",
  ean_grade: "ean grade",
  sku_size: "sku size",
  box_quantity: "box qty",
  pallete_quantity: "pallete qty",
  price: "$",
  product_name: "product name",
  sim_type: "sim type",
  card_size: "card size",
  specification: "specification",
  graphical_reference: "graphical reference",
  profile: "profile",
  "Profile(Vendor)": "profile(vendor)",
  "Perso Code(Idemia only)": "perso code(idemia only)",
  "Profile(IDEMIA)": "profile(idemia)",
  sim_chip: "sim chip",
  date_if: "date (if)",
  date_of: "date (of)",
  date_csv: "date (csv)",
  date_bss: "date (bss)",
  address_pool: "range",
  imsi_start: "imsi start",
  imsi_end: "imsi end",
  msisdn_start: "msisdn start",
  msisdn_end: "msisdn end",
  order_comment: "comment",
  edrx_ef_ad: "edrx (ef_ad)",
  applet_multi_imsi: "applet multi-imsi",
};

const filter = (filterExpression, setFilterError) => {
  // if (!filterExpression) {
  //   console.log(filterExpression);
  //   return [];
  // }
  try {
    const queryStringArr = [];
    // const exactMatchArr = [];
    const generalExpressionArray = filterExpression.split("||");
    generalExpressionArray.forEach((expression, index) => {
      const filters = expression.split(" ").filter(element => element);
      queryStringArr.push(filters);
    })
    setFilterError(false);

    return queryStringArr;
  } catch (err) {
    setFilterError(true);
    return [];
  }
};

const GlobalFilter = ({
  rows,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  useAsyncDebounce,
  data,
  setQueryStrings,
}) => {
  const count = rows.length;
  const [value, setValue] = React.useState(globalFilter);
  const [filterError, setFilterError] = useState(false);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <div style={{ marginRight: 20, verticalAlign: "baseline" }}>
      <span
        style={{ fontSize: "16px", marginRight: "5px", lineHeight: "40px" }}
      >
        Search:{" "}
      </span>
      <TextField
        sx={{
          width: "400px",
        }}
        error={filterError}
        helperText={filterError ? "Query error" : ""}
        size="small"
        variant="outlined"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          // onChange(e.target.value);
        }}
        placeholder={`Filter expression`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
      <Button
        sx={{
          bottom: "3px",
          marginLeft: "16px",
        }}
        variant="contained"
        onClick={() => {
          setQueryStrings(filter(value, setFilterError));
        }}
      >
        Run query
      </Button>
      <span
        style={{ fontSize: "16px", marginLeft: "15px", lineHeight: "40px" }}
      >
        {count} records found
      </span>
    </div>
  );
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  useAsyncDebounce: PropTypes.func,
};

export default GlobalFilter;
