import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

const highlight = (value, globalFilter) => {
    // check on start if values are valid
    // findAll() string method
    // const newValue = `${value}`.toLowerCase();
    if (typeof value === "number") value = value.toString();
    if (typeof value === "string" && typeof globalFilter === "string") {
        const compareValue = value.toLowerCase();
        const compareFilter = globalFilter.toLowerCase();
        if (compareValue.includes(compareFilter)) {
            const startIndex = compareValue.indexOf(compareFilter);

            const firstPart = startIndex === 0 ? "" : value.substring(0, startIndex);
            const middlePart = value.substring(startIndex, startIndex + compareFilter.length);
            const endPart =
                startIndex === compareValue.length - 1
                    ? ""
                    : value.substring(startIndex + compareFilter.length);
            return (
                <span>
                    {firstPart}
                    <mark>{middlePart}</mark>
                    {endPart}
                </span>
            );
        }
    }
    return value || "-";
};

export const columns = [
    {
        // Build our expander column
        id: "expander", // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>{isAllRowsExpanded ? "👇" : "👉"}</span>
        ),
        Cell: ({ row }) => (
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            <div>
                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            // We can even use the row.depth property
                            // and paddingLeft to indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                    })}
                >
                    {row.isExpanded ? <ArrowDropUpOutlinedIcon fontSize="large"/> : <ArrowDropDownOutlinedIcon fontSize="large"/>}
                </span>
            </div>
        ),
    },
    {
        Header: "State",
        accessor: "status",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Vendor",
        accessor: "vendor",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Batch ID",
        accessor: "batch_id",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Process",
        accessor: "process",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Process Number",
        accessor: "process_number",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "ICCID Start",
        accessor: "iccid_start",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "ICCID End",
        accessor: "iccid_end",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "JIRA",
        accessor: "jira",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Quantity",
        accessor: "quantity",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Form factor",
        accessor: "form_factor",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Date (PO)",
        accessor: "date_po",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "BSS Order ID",
        accessor: "bss_order_id",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "EAN Code",
        accessor: "ean_code",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "EAN Grade",
        accessor: "ean_grade",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "SKU Size",
        accessor: "sku_size",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Box Qty",
        accessor: "box_quantity",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Pallete Qty",
        accessor: "pallete_quantity",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "$",
        accessor: "price",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Product Name",
        accessor: "product_name",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "SIM Type",
        accessor: "sim_type",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Card Size",
        accessor: "card_size",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Specification",
        accessor: "specification",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Graphical reference",
        accessor: "graphical_reference",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Profile",
        accessor: "profile",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        //! does not exist?
        Header: "Profile(Vendor)",
        accessor: "Profile(Vendor)",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        //! does not exist?
        Header: "Perso Code(Idemia only)",
        accessor: "Perso Code(Idemia only)",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        //! does not exist?
        Header: "Profile(IDEMIA)",
        accessor: "Profile(IDEMIA)",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "SIM Chip",
        accessor: "sim_chip",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Date (IF)",
        accessor: "date_if",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Date (OF)",
        accessor: "date_of",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Date (CSV)",
        accessor: "date_csv",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Date (BSS)",
        accessor: "date_bss",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Range",
        accessor: "address_pool",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "IMSI Start",
        accessor: "imsi_start",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "IMSI End",
        accessor: "imsi_end",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "MSISDN start",
        accessor: "msisdn_start",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "MSISDN end",
        accessor: "msisdn_end",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Comment",
        accessor: "order_comment",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "eDRX (EF_AD)",
        accessor: "edrx_ef_ad",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    {
        Header: "Applet Multi-IMSI",
        accessor: "applet_multi_imsi",
        Cell: ({ cell: { value }, state: { globalFilter } }) => highlight(value, globalFilter),
    },
    // {
    //     Header: () => (
    //         <div>
    //             <span>
    //                 <Button variant="contained">Clear all filters</Button>
    //             </span>
    //         </div>
    //     ),
    //     id: "filterCleaner", // Make sure it has an ID
    //     cell: "123"
    // },
];
