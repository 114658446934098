import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { Button } from "@mui/material";

const highlight = (value, globalFilter) => {
  // check on start if values are valid
  // findAll() string method
  // const newValue = `${value}`.toLowerCase();
  if (typeof value === "object"){
    const keys = Object.keys(value)
    let displayValue = [];
    for (const key of keys) {
      displayValue[key] = <div key={key}>{value[key]}</div>
    }
    return displayValue;
  }
  if (typeof value === "number") value = value.toString();
  if (typeof value === "string" && typeof globalFilter === "string") {
    const compareValue = value.toLowerCase();
    const compareFilter = globalFilter.toLowerCase();
    if (compareValue.includes(compareFilter)) {
      const startIndex = compareValue.indexOf(compareFilter);

      const firstPart = startIndex === 0 ? "" : value.substring(0, startIndex);
      const middlePart = value.substring(
        startIndex,
        startIndex + compareFilter.length
      );
      const endPart =
        startIndex === compareValue.length - 1
          ? ""
          : value.substring(startIndex + compareFilter.length);
      return (
        <span>
          {firstPart}
          <mark>{middlePart}</mark>
          {endPart}
        </span>
      );
    }
  }
  return value || "-";
};

export const columns = [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? "👇" : "👉"}
      </span>
    ),
    Cell: ({ row }) => (
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      <span
        {...row.getToggleRowExpandedProps({
          style: {
            // We can even use the row.depth property
            // and paddingLeft to indicate the depth
            // of the row
            paddingLeft: `${row.depth * 2}rem`,
          },
        })}
      >
        {row.isExpanded ? (
          <ArrowDropUpOutlinedIcon fontSize="large" />
        ) : (
          <ArrowDropDownOutlinedIcon fontSize="large" />
        )}
      </span>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ cell: { value }, state: { globalFilter } }) =>
      highlight(value, globalFilter),
  },
  {
    Header: "Creator",
    accessor: "created_by",
    Cell: ({ cell: { value }, state: { globalFilter } }) =>
      highlight(value, globalFilter),
  },
  {
    Header: "Approve Order ID",
    accessor: "approve_id",
    Cell: ({ cell: { value }, state: { globalFilter } }) =>
      highlight(value, globalFilter),
  },
  {
    Header: "Approved by",
    accessor: "approved_by",
    Cell: ({ cell: { value }, state: { globalFilter } }) =>{
      return highlight(value, globalFilter)
    }
  },
  // {
  //     Header: "View Order",
  //     accessor: "view",
  //     Cell: ({ row }) => {
  //         return <Link to={`/orders/view/${row?.original?.approve_id}`}>View</Link>;
  //     },
  // },

  {
    Header: "Approve Order",
    accessor: "approve",
    Cell: ({ row, approveOrder }) => (
      <Button
        disabled={
          row.original.status == "approved" || row.original.status == "declined"
        }
        onClick={() => approveOrder(row?.original?.approve_id, true)}
      >
        Approve
      </Button>
    ),
  },
  {
    Header: "Decline Order",
    accessor: "decline",
    Cell: ({ row, approveOrder }) => (
      <Button
        disabled={
          row.original.status == "approved" || row.original.status == "declined"
        }
        onClick={() => approveOrder(row?.original?.approve_id, false)}
      >
        Decline
      </Button>
    ),
  },
];
