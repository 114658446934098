import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/pages/home";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useRecoilValue } from "recoil";
import { user_state } from "./components/root";
import LoginPage from "./components/pages/login";
import { Box, CssBaseline, Toolbar } from "@mui/material";
import NavigationBar from "./components/organisms/nav_bar";
import MenuDrawer from "./components/organisms/drawer";
import TablePage from "./components/pages/order_table";
import OrderPage from "./components/pages/order_approval";
import OrderViewPage from "./components/pages/order_view";

function RoutesContent() {
  const mdTheme = createTheme({
    navigationBar: {
      background: "#1e4d7d",
    },
  });

  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const ACTIVE_LOGIN = LoginPage;

  const user = useRecoilValue(user_state);
  if (!user) {
    return (
      <ThemeProvider theme={mdTheme}>
        <Routes>
          <Route path={"/*"} element={<ACTIVE_LOGIN />} />
          {/* <Route exact path="/login" element={<ACTIVE_LOGIN/>} /> */}
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    // move Theme provider to app
    <ThemeProvider theme={mdTheme}>
      <Routes>
        <Route exact path="/login" element={<ACTIVE_LOGIN />} />
      </Routes>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <NavigationBar toggleDrawer={toggleDrawer} open={open} />
        <MenuDrawer toggleDrawer={toggleDrawer} open={open} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box sx={{ marginLeft: "1em", marginRight: "1em" }}>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/table" element={<TablePage />} />
              <Route exact path="/orders" element={<OrderPage />} />
              <Route
                exact
                path="/orders/view/:orderId"
                element={<OrderViewPage />}
              />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
export default function Router() {
  return <RoutesContent />;
}
