import React from "react";

import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Grid,
  Collapse,
  Switch,
  FormControlLabel,
  Button,
  Chip,
  IconButton,
} from "@mui/material";
import GlobalFilter from "../../molecules/global_filter";
import {
  useTable,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useFilters,
  useExpanded,
  useAsyncDebounce,
} from "react-table";
import ApproveCardRow from "../../molecules/approve_card_renderer";
import TablePagination from "../../molecules/pagination";
import DefaultColumnFilter from "../../molecules/default_column_filter";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";

const OrderApprovalTable = ({ columns, data, approveOrder }) => {
  const [open, setOpen] = React.useState(false);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const toggleHeaders = () => {
    setOpen((prev) => !prev);
  };

  const resp = useTable(
    {
      columns,
      data,
      defaultColumn,
      approveOrder,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
    setFilter,
    //pagination stuff
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    state: { pageIndex, pageSize },
  } = resp;
  return (
    <Table {...getTableProps()} sx={{ tableLayout: "fixed" }}>
      <TableHead>
        <TableRow>
          <TableCell
            colSpan={visibleColumns.length}
            sx={{
              textAlign: "left",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <GlobalFilter
              rows={rows}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              useAsyncDebounce={useAsyncDebounce}
            />
            <FormControlLabel
              control={<Switch checked={open} onChange={toggleHeaders} />}
              label="Show filters and sorting"
            />
            <Button
              variant="contained"
              disabled={state.filters == 0 && state.sortBy == 0}
              onClick={() => {
                setAllFilters([]);
                setSortBy([]);
              }}
            >
              Clear all filters
            </Button>
            <TablePagination
              gotoPage={gotoPage}
              canPreviousPage={canPreviousPage}
              previousPage={previousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageOptions={pageOptions}
              pageSize={pageSize}
              setPageSize={setPageSize}
            />
          </TableCell>
        </TableRow>
        {(() => {
          if (state.filters.length > 0) {
            return (
              <TableRow>
                <TableCell
                  sx={{ justifyContent: "space-between", borderBottom: "none" }}
                >
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column, i) => {
                      return column.isSorted ? (
                        <Chip
                          key={`${i}_filterValue`}
                          label={`Sort by: ${column.Header} (${
                            column.isSortedDesc ? "Desc" : "Asc"
                          })`}
                          onDelete={() => {
                            setSortBy([]);
                          }}
                        />
                      ) : null;
                    })
                  )}
                  {headerGroups.map((headerGroup) =>
                    headerGroup.headers.map((column, i) => {
                      return column.filterValue ? (
                        <Chip
                          key={`${i}_filterValue`}
                          label={`${column.Header} = ${column.filterValue}`}
                          onDelete={() => {
                            setFilter(column.id, "");
                          }}
                        />
                      ) : null;
                    })
                  )}
                </TableCell>
              </TableRow>
            );
          }
        })()}
        {headerGroups.map((headerGroup) => (
          <TableRow
            {...headerGroup.getHeaderGroupProps()}
            sx={{ overflow: "hidden" }}
          >
            <TableCell
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                borderBottom: open ? "" : "none",
              }}
            >
              <Collapse in={open}>
                <Grid container spacing={3} alignItems="center">
                  {headerGroup.headers.map((column, i) => {
                    if (
                      i === 0 ||
                      i === columns.length - 1 ||
                      i === columns.length - 2
                    )
                      return null;
                    return (
                      <Grid
                        key={`${i}_cell`}
                        item
                        xs={3}
                        sm={3}
                        md={3}
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <div
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            style={{ fontSize: 12, color: "black" }}
                          >
                            {column.render("Header")}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <IconButton color="primary">
                                    <ArrowDropDownCircleOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    color="primary"
                                    sx={{
                                      transform: "rotate(180deg) scaleX(-1)",
                                    }}
                                  >
                                    <ArrowDropDownCircleOutlinedIcon />
                                  </IconButton>
                                )
                              ) : (
                                <IconButton
                                  sx={{
                                    transform: "rotate(180deg) scaleX(-1)",
                                  }}
                                >
                                  <ArrowDropDownCircleOutlinedIcon />
                                </IconButton>
                              )}
                            </span>
                          </div>
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {page.map((row, i) => {
          prepareRow(row);
          return <ApproveCardRow key={`${i}_row`} row={row}/>;
        })}
        <TableRow sx={{textAlign: "center !important"}}>
            <TableCell
              colSpan={visibleColumns.length}
              sx={{
                borderTop: "1px solid",
                borderColor: "rgba(224, 224, 224, 1)",
                textAlign: "left",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <TablePagination
                gotoPage={gotoPage}
                canPreviousPage={canPreviousPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canNextPage={canNextPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                pageOptions={pageOptions}
                pageSize={pageSize}
                setPageSize={setPageSize}
              />
            </TableCell>
          </TableRow>
      </TableBody>
    </Table>
  );
};

export default OrderApprovalTable;
